/* RESPONSIBLE TEAM: team-help-desk-experience */
export interface AppSettingsWireFormat {
  help_center_locale?: string;
  disabled_inserters: string[];
  show_teammates_presence: boolean;
  fin_use_inbox_conversation_content: boolean;
  is_any_channel_installed: boolean;
  has_customer_tickets: boolean;
  has_back_office_tickets: boolean;
  has_tracker_tickets: boolean;
  email_remove_conversation_history: boolean;
  strip_inbound_email_links: boolean;
  attach_uploads_inline: boolean;
  use_trusted_domains_for_url_verification: boolean;
  trusted_domains: string;
  malicious_links: string[];
}

function cleanupTrustedDomains(trustedDomains: string): string[] {
  // remove new line and empty spaces
  return trustedDomains
    .split('\n')
    .join(',')
    .split(',')
    .map((domain) => domain.trim())
    .filter((domain) => !!domain);
}

export default class AppSettings {
  constructor(
    public helpCenterLocale: string | undefined,
    public disabledInserters: string[],
    public showTeammatesPresence: boolean,
    public finUseInboxConversationContent: boolean,
    public isAnyChannelInstalled: boolean,
    public hasCustomerTickets: boolean,
    public hasBackOfficeTickets: boolean,
    public hasTrackerTickets: boolean,
    public emailRemoveConversationHistory: boolean,
    public stripInboundEmailLinks: boolean,
    public attachUploadsInline: boolean,
    public useTrustedDomainsForUrlVerification: boolean,
    public trustedDomains: string[],
    public maliciousLinks: string[],
  ) {}

  static deserialize(data: AppSettingsWireFormat): AppSettings {
    return new AppSettings(
      data.help_center_locale,
      data.disabled_inserters,
      data.show_teammates_presence,
      data.fin_use_inbox_conversation_content,
      data.is_any_channel_installed,
      data.has_customer_tickets,
      data.has_back_office_tickets,
      data.has_tracker_tickets,
      data.email_remove_conversation_history,
      data.strip_inbound_email_links,
      data.attach_uploads_inline,
      data.use_trusted_domains_for_url_verification,
      data.trusted_domains ? cleanupTrustedDomains(data.trusted_domains) : [],
      data.malicious_links,
    );
  }
}
