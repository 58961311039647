/* RESPONSIBLE TEAM: team-standalone */
import EditorConfig from 'embercom/objects/visual-builder/configuration/editor';

export default class NewZendeskTicketBotConfig extends EditorConfig {
  get supportsConversationChannelTargeting(): boolean {
    return false;
  }

  get supportsSideSheet(): boolean {
    return false;
  }
}
