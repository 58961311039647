/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';

import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class TicketsConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;

  @attr('number') declare finZendeskUserId: number;
  @attr('number') declare workflowRulesetId: number;
  @attr('boolean') declare manageAssignmentTrigger: boolean;

  get admins() {
    return this.finStandaloneService.zendeskTicketsSetupData.admins;
  }

  get selectedAdminName() {
    let admin = this.admins.filter((admin) => admin.id === this.finZendeskUserId);
    return admin[0]?.name;
  }

  async updateManageAssignmentTrigger(manageAssignmentTrigger: boolean) {
    await post(
      `/ember/standalone/zendesk_configurations/${this.id}/update_manage_assignment_trigger`,
      {
        should_manage: manageAssignmentTrigger,
      },
    );

    this.manageAssignmentTrigger = manageAssignmentTrigger;
  }
}
