/* RESPONSIBLE TEAM: team-data-interop */

import EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import type { PathConfigGenerationParams } from 'embercom/objects/visual-builder/configuration/editor';
import { AiAgentWorkflowPathConfig } from 'embercom/objects/visual-builder/configuration/path';

export default class AiAgentWorkflowEditorConfig extends EditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new AiAgentWorkflowPathConfig({ path, editorState });
  }

  get showGoalsPanel(): boolean {
    return false;
  }
}
