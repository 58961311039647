/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Model, {
  type AsyncHasMany,
  type SyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { action } from '@ember/object';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import pluralise from 'embercom/lib/inflector';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import ajax, { put } from 'embercom/lib/ajax';
import { mapAdminToId } from './mixins/admin-mapper';
import {
  CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION,
  CAN_MANAGE_ARTICLES_PERMISSION,
} from '../lib/articles/constants';
import { tracked } from '@glimmer/tracking';
import type ArticleGroup from 'embercom/models/articles/article-group';
import type ArticleContent from 'embercom/models/articles/article-content';
import type SupportedLocale from 'embercom/models/articles/site/supported-locale';
import type Store from '@ember-data/store';
import type CustomIntlService from 'embercom/services/intl';
import type Admin from 'embercom/models/admin';
import type Tag from 'embercom/models/tag';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type TaskGenerator } from 'ember-concurrency';
import { dependentKeyCompat } from '@ember/object/compat';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { EntityType } from 'embercom/models/data/entity-types';
import { type Taggable } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { UPDATE_TAGGING_URL } from 'embercom/lib/knowledge-hub/constants';
import type ContentStatistic from 'embercom/models/outbound/content-statistic';

export const MAX_SUMMARY_SIZE = 140;
export const MAX_TITLE_SIZE = 255;

export default class ArticleMultilingual extends Model.extend(TaggingMixin) {
  @service declare appService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: CustomIntlService;

  get site(): HelpCenterSite {
    return this.store.peekAll('help-center-site').firstObject;
  }

  get app(): $TSFixMe {
    return this.appService.app;
  }

  @attr('date') declare createdAt: Date;
  @attr('number') declare order: number;
  get stats() {
    return this.lastStatsResponse;
  }
  @belongsTo('articles/article-group', { inverse: 'articles' })
  declare folder: ArticleGroup;
  @hasMany('articles/article-group', { inverse: 'containedArticles' })
  declare inCollections: AsyncHasMany<ArticleGroup>;
  @attr('string') declare targetUserType: string;
  @attr('boolean') declare excludeFromHomeScreenSuggestions: boolean;
  @attr('boolean') declare showRelatedArticles: boolean;
  @attr('date') declare lastEditedAt: Date;
  @attr('string') declare lastEditedById: string;
  @attr('string') declare title: string;
  @fragmentArray('articles/site/supported-locale')
  declare selectedLocales: SyncHasMany<SupportedLocale>;

  @mapAdminToId('app.humanAdmins', 'lastEditedById') declare lastEditedBy: Admin;

  @belongsTo('matching-system/ruleset', { async: false }) declare ruleset: $TSFixMe;

  @hasMany('tag', { async: true }) declare tags: AsyncHasMany<Tag>;
  @hasMany('tagging', { async: false }) declare taggings: SyncHasMany<$TSFixMe>;

  @hasMany('articles/article-content', { async: false, inverse: 'article' })
  declare articleContents: SyncHasMany<ArticleContent & Model>;

  @hasMany('outbound/content-statistic', { async: false })
  declare contentStats: SyncHasMany<ContentStatistic>;

  readonly entityType = EntityType.Article;

  requiredEditPermission = CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;

  get requiredDeletePermission() {
    if (this.publishedContents.length === 0) {
      return CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;
    }

    return CAN_MANAGE_ARTICLES_PERMISSION;
  }

  @attr('boolean') declare readOnly: boolean;

  get selectedUserTypes(): string[] {
    return this.ruleset.rolePredicateGroup.userPredicate?.userTypes || [];
  }

  get pluralSelectedUserTypes(): string[] | undefined {
    if (this.selectedUserTypes) {
      return this.selectedUserTypes.map((userType) => pluralise(userType));
    }
    return undefined;
  }

  get hasRestrictedAudience(): boolean {
    if (!this.ruleset) {
      // This check is not needed anymore, but removing it will need us to update hundreds of
      // article mocks in our specs which we don't have a bandwidth for at the moment.
      return false;
    }
    return this.ruleset.userType !== 'everyone' || this.ruleset.predicateGroup.hasPredicates;
  }

  get orderedArticleContents() {
    return this.site.selectedLocalesOrdered.map((locale: SupportedLocale) =>
      this.contentByLocale(locale.localeId),
    );
  }

  get helpCenterIds(): Array<string> {
    return [...new Set(this.inCollections.map((collection) => collection.helpCenterId))];
  }

  get collectionForDefaultHelpCenter(): ArticleGroup | undefined {
    if (!this.helpCenterService.allSites.firstObject) {
      return;
    }
    return this.inCollections.find((collection: ArticleGroup) => {
      return collection.helpCenterId === this.helpCenterService.allSites.firstObject.id;
    });
  }

  get firstContent() {
    return this.orderedArticleContents.firstObject;
  }

  get selectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  @dependentKeyCompat
  get selectedLocalizedContent() {
    return this.contentByLocale(this.selectedLocaleId);
  }

  @dependentKeyCompat
  get hasSelectedLocalizedContent() {
    return !!this.selectedLocalizedContent;
  }

  @dependentKeyCompat
  get defaultLocaleId(): string {
    return this.helpCenterService.site?.locale;
  }

  get defaultLocalizedContent() {
    return this.contentByLocale(this.defaultLocaleId);
  }

  get firstDefaultLocaleId() {
    return this.helpCenterService.allUniqueDefaultLocales.firstObject?.localeId;
  }

  get hasDefaultLocalizedContent() {
    return !!this.defaultLocalizedContent;
  }

  get firstDefaultLocalizedContent() {
    return this.contentByLocale(this.firstDefaultLocaleId);
  }

  get hasFirstDefaultLocalizedContent() {
    return !!this.firstDefaultLocalizedContent;
  }

  get collection() {
    // @ts-ignore - folder is a proxy Ember object so we need to use get
    if (this.folder.get('isHome') || this.folder.get('parent.isHome')) {
      return this.folder;
    }

    // @ts-ignore
    if (this.folder.get('parent.isCollection')) {
      return this.folder.get('parent');
    }
    // @ts-ignore
    return this.folder.get('parent.parent');
  }

  get defaultTitle() {
    return this.intl.t('knowledge-hub.content-editor.article-content.placeholder-title');
  }

  get section() {
    // @ts-ignore
    if (this.folder.get('isCollection')) {
      return null;
    }

    // @ts-ignore
    if (this.folder.get('parent.isCollection')) {
      return this.folder;
    }
    return this.folder.get('parent');
  }

  get subSection() {
    // @ts-ignore
    if (this.folder.get('isCollection')) {
      return null;
    }

    // @ts-ignore
    if (this.folder.get('parent.isCollection')) {
      return null;
    }
    return this.folder;
  }

  get public() {
    return !this.folder.get('isHome');
  }

  get titleForDisplay() {
    return this.firstContent?.titleForDisplay;
  }
  get availableTitle() {
    let content = this.orderedArticleContents.find((c) => {
      if (c === null || c === undefined) {
        return false;
      }
      return c.titleForDisplay && c.titleForDisplay !== '';
    });

    return content?.titleForDisplay || '';
  }

  get publishedContents() {
    return this.articleContents.filter((articleContent) => articleContent.isPublished);
  }
  get publishedContentsLocaleIds() {
    return this.publishedContents.map((content) => content.locale);
  }

  get canBePublished() {
    return this.orderedArticleContents.some((content) => {
      if (content) {
        return content.state === 'draft' || content.latestVersionId !== content.liveVersionId;
      }
      return false;
    });
  }

  get canBeUnpublished() {
    return this.orderedArticleContents.some((content) => {
      if (content) {
        return content.state === 'published';
      }
      return false;
    });
  }

  get siteProvider() {
    let contents = this.articleContents.filter((content) => content);
    return contents.length > 0 ? contents[0].siteProvider : null;
  }

  get analyticsData() {
    return {
      object: 'article',
      article_id: this.id,
      is_public: this['public'],
    };
  }

  get missingTitlePlaceholder() {
    return this.intl.t('articles.missing-title-placeholder');
  }

  get needsReview() {
    return this.articleContents.isAny('needsReview');
  }

  @action async removeTagging(tag: Tag) {
    let admin = this.app.currentAdmin;
    let addedTags: Tag[] = [];
    let removedTags = [tag];
    await this.updateTaggings(admin, addedTags, removedTags).catch(() => {});
    // @ts-ignore - refreshTaggings comes from TaggingMixin
    return this.refreshTaggings(admin, addedTags, removedTags);
  }

  get taggable(): Taggable {
    return {
      tags: this.tags,
      taggings: this.taggings,
      type: 'article',
      updateTaggings: async (
        admin: Admin,
        addedTags: Tag[],
        removedTags: Tag[],
        _initialTags: Tag[],
      ) => {
        return await this.updateTaggings(admin, addedTags, removedTags);
      },
    };
  }

  get existingLocales(): string[] {
    return this.articleContents.map((content) => content.locale);
  }
  get isMultilingual(): boolean {
    return this.articleContents.toArray().length > 1;
  }

  async updateTaggings(admin: Admin, addedTags: Tag[], removedTags: Tag[]) {
    try {
      let articleUpdate = put(UPDATE_TAGGING_URL, {
        app_id: this.app.id,
        added_tag_ids: addedTags.map((tag) => tag.id),
        removed_tag_ids: removedTags.map((tag) => tag.id),
        content_wrapper_id: this.id,
        content_wrapper_type: ContentWrapperType.ARTICLES,
      });

      let supportContentUpdate = put(UPDATE_TAGGING_URL, {
        app_id: this.app.id,
        added_tag_ids: addedTags.map((tag) => tag.id),
        removed_tag_ids: removedTags.map((tag) => tag.id),
        content_wrapper_id: this.id,
        entity_type: EntityType.Article,
        entity_id: this.id,
        content_wrapper_type: ContentWrapperType.SUPPORT_CONTENT,
      });
      await Promise.all([articleUpdate, supportContentUpdate]);
    } catch (error) {
      // @ts-ignore - refreshTaggings comes from TaggingMixin
      this.refreshTaggings(admin, removedTags, addedTags);
      throw error;
    }
  }

  createContentIfNone(locale: string) {
    if (!this.contentByLocale(locale)) {
      let content = this.store.createRecord('articles/article-content', {
        locale,
        jsonBlocks: [],
        jsonBlocksForEditing: [],
        authorId: this.app.currentAdmin.id,
      });
      this.articleContents.pushObject(content);
    }
  }

  async createContentWithVersionIfNone(locale: string): Promise<ArticleContent | null> {
    if (this.contentByLocale(locale)) {
      return null;
    }
    return this.store
      .createRecord('articles/article-content-for-editing', {
        articleId: this.id,
        locale,
        jsonBlocks: [],
        jsonBlocksForEditing: [],
        authorId: this.app.currentAdmin.id,
      })
      .save();
  }

  reorder(newIndex: number, helpCenterId = null) {
    return ajax({
      type: 'POST',
      url: `/ember/articles/${this.id}/reorder`,
      data: JSON.stringify({
        order: newIndex,
        // @ts-ignore
        app_id: this.get('app.id'),
        help_center_id: helpCenterId,
      }),
    });
  }

  contentByLocale(locale: string): ArticleContent | undefined {
    return this.articleContents.findBy('locale', locale);
  }

  createMessengerCard(
    locale: string,
    conversationId: string,
    anchor_link = null,
    helpCenterId = undefined,
  ) {
    return ajax({
      url: `/ember/articles/${this.id}/messenger_card`,
      data: {
        locale,
        // @ts-ignore
        app_id: this.get('app.id'),
        conversation_id: conversationId,
        anchor_link,
        help_center_id: helpCenterId,
      },
    });
  }

  @task *updateArticlesMultiple(): TaskGenerator<$TSFixMe> {
    let groupMemberships = this.inCollections.map((collection) => ({
      parent_id: collection.get('id'),
      help_center_id: collection.get('helpCenterId'),
    }));

    let data = {
      ...this.serialize(),
      // @ts-ignore
      app_id: this.get('app.id'),
      group_memberships: groupMemberships,
    };

    return yield ajax({
      type: 'PUT',
      url: `/ember/articles/${this.id}`,
      data: JSON.stringify(data),
    });
  }

  @task *updateSettings(): TaskGenerator<$TSFixMe> {
    return yield ajax({
      type: 'PUT',
      url: `/ember/articles/${this.id}/settings`,
      data: JSON.stringify({
        ruleset: this.ruleset.serialize(),
        group_memberships: this.inCollections.map((folder) => ({
          parent_id: folder.get('id'),
          // @ts-ignore
          help_center_id: folder.get('helpCenterId') || this.get('site.id'),
        })),
        // @ts-ignore
        collection_id: this.get('folder.id'),
        // @ts-ignore
        help_center_id: this.get('folder.helpCenterId') || this.get('site.id'),
        target_user_type: this.targetUserType,
        // @ts-ignore
        app_id: this.get('app.id'),
        exclude_from_home_screen_suggestions: this.excludeFromHomeScreenSuggestions,
        show_related_articles: this.showRelatedArticles,
      }),
    });
  }

  @task *updateSettingsMultiple(): TaskGenerator<$TSFixMe> {
    return yield ajax({
      type: 'PUT',
      url: `/ember/articles/${this.id}/settings`,
      data: JSON.stringify({
        ruleset: this.ruleset.serialize(),
        group_memberships: this.inCollections.map((folder) => ({
          parent_id: folder.get('id'),
          help_center_id: folder.get('helpCenterId') || this.site.id,
        })),
        // TODO There are still a lot of places that refers to article_group_id,
        // providing collection_id will update that let's keep it until we remove all the references
        // @ts-ignore
        collection_id: this.get('folder.id'),
        target_user_type: this.targetUserType,
        // @ts-ignore
        app_id: this.get('app.id'),
        exclude_from_home_screen_suggestions: this.excludeFromHomeScreenSuggestions,
        show_related_articles: this.showRelatedArticles,
      }),
    });
  }

  @tracked lastStatsResponse = null;

  @task *loadStats(): TaskGenerator<$TSFixMe> {
    let response = yield ajax({
      url: `/ember/articles/${this.id}/stats`,
      data: {
        // @ts-ignore
        app_id: this.get('app.id'),
      },
    });
    this.set('lastStatsResponse', response);
    return response;
  }

  static async updateArticleCountsForApp(app: $TSFixMe) {
    try {
      let counts = await ajax({
        url: '/ember/articles/counts',
        type: 'GET',
        data: {
          app_id: app.id,
          admin_id: app.currentAdmin.id,
        },
      });

      app.setProperties({
        allArticlesCount: counts.all,
        finishedArticlesCount: counts.finished,
        draftArticlesCount: counts.draft,
      });

      return counts;
    } catch (_e) {
      return {};
    }
  }
}
