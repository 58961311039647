/* RESPONSIBLE TEAM: team-knowledge-interop */

import type Store from '@ember-data/store';
import type Ember from 'ember';
import { inject as service } from '@ember/service';
import {
  humanReadableObjectNames,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import {
  toggleCopilotAvailability,
  toggleChatbotAvailability,
  type AIConsumable,
} from 'embercom/models/content-service/ai-consumable';
import { FinAvailability } from 'embercom/lib/ai-content-library/constants';
import { type State, trackedFunction } from 'ember-resources/util/function';
import { type EmberCPValidationsResult } from 'embercom/validations/typescript/ember-cp-validations-compat';
import { dependentKeyCompat } from '@ember/object/compat';
import { validateKnowledgeHubContent } from 'embercom/validations/typescript/knowledge-hub/content-editor';
import { type LocalizedKnowledgeContentVersion } from 'embercom/objects/knowledge-hub//localized-knowledge-content-version';
import type Admin from 'embercom/models/admin';
import type FolderMembership from 'embercom/models/content-service/folder-membership';
import type { EntityType } from 'embercom/models/data/entity-types';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type IntlService from 'ember-intl/services/intl';
import type { KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import { type KnowledgeHubContent } from 'embercom/objects/knowledge-hub/knowledge-hub-content';
import { type AsyncHasMany, type SyncHasMany } from '@ember-data/model';
import type Tag from 'embercom/models/tag';
import type { StatisticKeyName } from 'embercom/models/data/outbound/types';

/**
 * Interface for the localized content of a Knowledge Hub content item.
 */
export interface LocalizedKnowledgeContent extends AIConsumable {
  /** Return what components are used in the editor experience for this content type, this also controls the view mode */
  get editorConfig(): KnowledgeHubEditorConfig;
  /** Returns the human readable name of this entity. */
  get humanReadableContentName(): string;
  /** Return whether the content has unsaved changes, probably using the ember hasDirtyAttributes property */
  get hasUnsavedChanges(): Ember.ComputedProperty<boolean, boolean>;
  /** Return whether the content is currently saving, probably using the ember isSaving property */
  get saving(): Ember.ComputedProperty<boolean, boolean>;
  get isLive(): boolean;
  get entityId(): number;
  /**
   * Returns the name of the entity type, e.g. 'article' for EntityType.Article.
   * For the the human readable name, see humanReadableContentName.
   */
  get entityName(): string;
  /** Return the parent content item. This is used when interacting with the folder membership, with other
   * localizations, or with anything else that isn't specific to the current localized knowledge content.
   */
  get parentContent(): KnowledgeHubContent;
  /** Return the permission that is required to edit this content item */
  get requiredEditPermissionForKnowledgeHub(): string;
  /** Return the permission that is required to publish this content item */
  get requiredPublishPermissionForKnowledgeHub(): string;
  /** Return the segment membership ids */
  get aiContentSegmentIds(): number[];
  /** Return the admin model of the creator. You should override `creatorId` to use this. */
  get creator(): Admin | undefined;
  /** Return the admin who last updated this. You should override `updaterId` */
  get updater(): Admin | undefined;
  /** Return the admin who is set as the author of this. You should override `writerId` */
  get writer(): Admin | undefined;
  /** Return the admin who last updated this. You should override `updaterId` */
  set writer(writer: Admin | undefined);
  /** Return the language code of the content item */
  get locale(): string | undefined;
  /** Set the language code of the content item */
  set locale(value: string | undefined);
  /** The creation date of this piece of content (by `creator`) */
  get createdAt(): string | Date;
  /** The last date on which this piece of content was updated (by `updater`) */
  get updatedAt(): string | Date;
  /** Return a default title to be stored on the object in the back end in order to allow for sorting, etc. eg: 'untitled' */
  get defaultTitle(): string;
  /** Use this if you need to load additional models when the content is being viewed / edited in the editor. */
  loadRelatedContent(action: ContentAction): Promise<void>;
  updateLanguageCode?(languageCode: string): Promise<void>;
  /** Aimed primarily at articles, where there can be draft versions which are not published */
  hasUnpublishedDraftVersion?: boolean;
  needsReview?: boolean;
  /** Controls the UI state shown to users for whether an object is used by copilot */
  isEligibleAndAvailableToCopilot: State<boolean>;
  /** Controls the UI state shown to users for whether an object is used by chatbot */
  isEligibleAndAvailableToChatbot: State<boolean>;
  /** Common validations for LocalizedKnowledgeHubContent Types, e.g: every item that can be edited should have a title to be allowed to be saved */
  get validations(): EmberCPValidationsResult;
  get autoSaveEnabled(): boolean;
  /** Return the latest version of the content for content that's versioned, otherwise return the object itself. */
  get latestVersion(): LocalizedKnowledgeContentVersion | undefined;
  publishContent: () => Promise<void>;
  unpublishContent: () => Promise<void>;
  /** Return the publishing state of this piece of content */
  get publishingStatus(): 'draft' | 'published' | undefined;
  title?: string;
  /** An object containing all info needed for an entity to be tagged */
  get taggable(): Taggable | undefined;

  get statisticKeys(): StatisticKeyName[];
}

export type Taggable = {
  tags: AsyncHasMany<Tag>;
  taggings: SyncHasMany<any>;
  type: string;
  updateTaggings: (
    admin: Admin,
    addedTags: Tag[],
    removedTags: Tag[],
    initialTags?: Tag[],
  ) => Promise<void>;
  saveTags?: () => void;
  removeTagging?: (tag: Tag) => void;
};

export enum ContentAction {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

type Constructable = new (...args: any[]) => object;

/**
 * Provides some default implementation of the `LocalizedKnowledgeContent` interface.
 */
export function LocalizedKnowledgeContentMixin<BC extends Constructable>(Base: BC) {
  abstract class LocalizedKnowledgeContentMixin extends Base implements LocalizedKnowledgeContent {
    @service declare appService: $TSFixMe;
    @service declare intl: IntlService;
    @service declare knowledgeHubService: KnowledgeHubService;
    @service declare store: Store;

    abstract get editorConfig(): KnowledgeHubEditorConfig;
    abstract get hasUnsavedChanges(): Ember.ComputedProperty<boolean, boolean>;
    abstract get saving(): Ember.ComputedProperty<boolean, boolean>;
    abstract get chatbotAvailabilityOption(): FinAvailability;
    abstract get copilotAvailabilityOption(): FinAvailability;
    abstract set chatbotAvailabilityOption(_availability: FinAvailability);
    abstract set copilotAvailabilityOption(_availability: FinAvailability);
    /** The property that is used to store the folder membership. This will usually be a sync belongsTo association. */
    declare folderMembership?: FolderMembership;
    /** Return the entity id of the content item. Note if the folder membership is not based on the content item, you'll need to override membershipEntityId and membershipEntityType to return the correct entity. */
    abstract get entityId(): number;
    abstract get entityName(): string;
    abstract get entityType(): EntityType;
    abstract get parentContent(): KnowledgeHubContent;
    abstract get requiredEditPermissionForKnowledgeHub(): string;
    abstract get requiredPublishPermissionForKnowledgeHub(): string;
    abstract get aiContentSegmentIds(): number[];
    abstract get latestVersion(): LocalizedKnowledgeContentVersion | undefined;
    abstract get locale(): string | undefined;
    abstract set locale(value: string | undefined);
    abstract get createdAt(): string | Date;
    abstract get updatedAt(): string | Date;
    abstract get taggable(): Taggable | undefined;

    /** Return the admin id of the creator */
    get creatorId(): number | undefined {
      return undefined;
    }

    get creator(): Admin | undefined {
      if (this.creatorId) {
        return this.store.peekRecord('admin', this.creatorId);
      }
      return undefined;
    }

    /** Return the admin id of the last admin to update this */
    get updaterId(): number | undefined {
      return undefined;
    }

    get updater(): Admin | undefined {
      if (this.updaterId) {
        return this.store.peekRecord('admin', this.updaterId);
      }
      return undefined;
    }

    /** Return the admin id of the admin who wrote this */
    get writerId(): string | undefined {
      return undefined;
    }

    /** Update the admin id of the admin who wrote this */
    set writerId(_writerId: string | undefined) {
      return;
    }

    get writer(): Admin | undefined {
      if (this.writerId) {
        return this.store.peekRecord('admin', this.writerId);
      }
      return undefined;
    }

    set writer(writer: Admin | undefined) {
      this.writerId = writer ? writer.id : undefined;
    }

    get humanReadableContentName() {
      if (this.entityType) {
        return humanReadableObjectNames[this.entityType];
      }
      return '';
    }

    async loadRelatedContent(_action: ContentAction) {
      return;
    }

    toggleCopilotAvailability(): Promise<void> {
      return toggleCopilotAvailability(this.app.id, this);
    }

    toggleChatbotAvailability(): Promise<void> {
      return toggleChatbotAvailability(this.app.id, this);
    }

    get availableToCopilot(): boolean {
      return this.copilotAvailabilityOption === this.copilotAvailableValue;
    }

    get availableToChatbot(): boolean {
      return this.chatbotAvailabilityOption === this.chatbotAvailableValue;
    }

    get localeIsEligibleForAi(): boolean {
      if (this.locale === undefined) {
        return false;
      }
      return this.knowledgeHubService.availableLocaleIds.includes(this.locale);
    }

    get hasUnpublishedDraftVersion() {
      return false;
    }

    async isEligibleForCopilot(): Promise<boolean> {
      return this.localeIsEligibleForAi;
    }

    async isEligibleForChatbot(): Promise<boolean> {
      return this.localeIsEligibleForAi;
    }

    get app() {
      return this.appService.app;
    }

    get isLive() {
      return this.chatbotAvailabilityOption === this.copilotAvailableValue;
    }

    /** Default implementation of autoSaveEnabled, override this if you want to disable auto save for a content type based on certain properties */
    get autoSaveEnabled() {
      return true;
    }

    @dependentKeyCompat
    get validations(): EmberCPValidationsResult {
      return validateKnowledgeHubContent(this);
    }

    get copilotAvailableValue(): FinAvailability {
      return FinAvailability.ON;
    }

    get chatbotAvailableValue(): FinAvailability {
      return FinAvailability.ON;
    }

    isEligibleAndAvailableToCopilot = trackedFunction(this, async () => {
      return this.availableToCopilot && (await this.isEligibleForCopilot());
    });

    isEligibleAndAvailableToChatbot = trackedFunction(this, async () => {
      return this.availableToChatbot && (await this.isEligibleForChatbot());
    });

    get defaultTitle() {
      return this.intl.t(`knowledge-hub.content-editor.untitled.${objectNames[this.entityType]}`);
    }

    async publishContent() {
      return;
    }

    async unpublishContent() {
      return;
    }

    get publishingStatus(): 'draft' | 'published' | undefined {
      return undefined;
    }

    get statisticKeys(): StatisticKeyName[] {
      return [];
    }
  }
  return LocalizedKnowledgeContentMixin;
}
