/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';

import { post } from 'embercom/lib/ajax';

import type SunshineConfiguration from 'embercom/models/standalone/sunshine-configuration';
import type TicketsIntegration from 'embercom/models/standalone/tickets-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export enum ZendeskConfigurationStatus {
  Pending = -1,
  Created = 0,
  Paused = 1,
  Enabled = 2,
}

export default class ZendeskConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;

  @attr('string') declare subdomain: string;
  @attr('string') declare username: string;
  @attr('string') declare token: string;
  @attr('number', { defaultValue: ZendeskConfigurationStatus.Pending })
  declare ticketState: ZendeskConfigurationStatus;
  @attr('number', { defaultValue: ZendeskConfigurationStatus.Pending })
  declare messengerState: ZendeskConfigurationStatus;

  @belongsTo('standalone/sunshine-configuration', { async: false })
  declare sunshineIntegration: SunshineConfiguration;

  @belongsTo('standalone/tickets-configuration', { async: false })
  declare ticketsIntegration: TicketsIntegration;

  get canUpdateAPISettings() {
    return this.subdomain && this.username && this.token;
  }

  get canUpdateSunshineSettings() {
    return (
      this.sunshineIntegration.sunshineAppId &&
      this.sunshineIntegration.apiKeyId &&
      this.sunshineIntegration.apiSecret &&
      !this.isPendingSetupForTickets
    );
  }

  get isEnabledForTickets() {
    return this.ticketState === ZendeskConfigurationStatus.Enabled;
  }

  get isDisabledForTickets() {
    return (
      this.ticketState === ZendeskConfigurationStatus.Paused ||
      this.ticketState === ZendeskConfigurationStatus.Created ||
      this.ticketState === ZendeskConfigurationStatus.Pending
    );
  }

  get isPendingSetupForTickets() {
    return this.ticketState === ZendeskConfigurationStatus.Pending;
  }

  get isEnabledForSunshine() {
    return this.messengerState === ZendeskConfigurationStatus.Enabled;
  }

  get isDisabledForSunshine() {
    return (
      this.messengerState === ZendeskConfigurationStatus.Paused ||
      this.messengerState === ZendeskConfigurationStatus.Created ||
      this.messengerState === ZendeskConfigurationStatus.Pending
    );
  }

  get isPendingSetupForSunshine() {
    return this.messengerState === ZendeskConfigurationStatus.Pending;
  }

  async revoke() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/revoke`);
    this.subdomain = '';
    this.username = '';
    this.token = '';
    this.ticketState = ZendeskConfigurationStatus.Pending;
    this.messengerState = ZendeskConfigurationStatus.Pending;
    this.sunshineIntegration.revoke();
    taskFor(this.finStandaloneService.loadZendeskTicketsSetupData).perform();
  }

  async revokeSunshine() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/revoke_sunshine`);
    this.messengerState = ZendeskConfigurationStatus.Pending;
    this.sunshineIntegration.revoke();
  }

  async setFinUser(finUserId: number) {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/set_fin_user_for_tickets`, {
      fin_zendesk_user_id: finUserId,
    });

    this.ticketsIntegration.finZendeskUserId = finUserId;

    taskFor(this.finStandaloneService.loadZendeskTicketsSetupData).perform();

    await this.reload();
  }

  async activateForTickets() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/activate_for_tickets`);
    await this.reload();
  }

  async deactivateForTickets() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/deactivate_for_tickets`);
    await this.reload();
  }

  async activateForMessenger() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/activate_for_messenger`);
    await this.reload();
  }

  async deactivateForMessenger() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/deactivate_for_messenger`);
    await this.reload();
  }
}
