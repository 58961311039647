/* RESPONSIBLE TEAM: team-ai-agent */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';
import BotIntroPanelConfiguration from 'embercom/objects/content-editor/panels/custom/ai-agent-profile/bot-intro/panel-configuration';
import BotModeContentPanelConfiguration from 'embercom/objects/content-editor/panels/custom/ai-agent-profile/bot-mode/panel-configuration';
import AnswersPanelConfiguration from 'embercom/objects/content-editor/panels/custom/ai-agent-profile/answers/panel-configuration';
import GenericTriagePanelConfiguration from 'embercom/objects/content-editor/panels/custom/ai-agent-profile/generic-triage/panel-configuration';
import PreHandoverPanelConfiguration from '../../panels/custom/ai-agent-profile/pre-handover/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import AudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import { CHANNEL_EMAIL } from 'embercom/lib/operator/custom-bots/constants';

export default function generateEditModeConfig(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');
  let finOptInService = container.lookup('service:finOptInService');
  let contentEditorService = container.lookup('service:contentEditorService');
  let attributeService = container.lookup('service:attributeService');

  let audienceEditorConfiguration = new StandardAudienceEditorConfiguration({
    showMatchBehavior: false,
    allowedUserRoles: ['user', 'visitor', 'lead'],
    showClientPredicates: false,
    showAudiencePreview: true,
    ignoredAttributeTypesForPreview: ['message', 'conversation', 'office_hours', 'team_capacity'],
    additionalAttributes: attributeService.visualBotBuilderConditionalAttributes.filter(
      (attr) => !attributeService.availabilityAttributes.includes(attr),
    ),
    container,
  });

  audienceEditorConfiguration.header = ComponentConfiguration.create({
    component: 'content-editor/panels/custom/triggerable-custom-bot/channel-targeting/body',
    config: {
      excludedChannels: [CHANNEL_EMAIL],
    },
  });
  audienceEditorConfiguration.audienceRulesEditor = ComponentConfiguration.create({
    component:
      'content-editor/audience-wrappers/content/triggerable-custom-bots/audience-rules-editor',
    config: audienceEditorConfiguration.inputs,
  });

  let audiencePanelConfiguration = {
    hideMatchBehavior: true,
    audienceEditorConfiguration,
    container,
    audienceHeaderConfiguration: AudienceHeaderConfiguration.create(
      {
        showChannelTargetingSummary: true,
        component: 'content-editor/panels/audience/content/triggerable-custom-bot/header',
        title: intl.t('ai-agent.profiles.config-generator.rules'),
      },
      { container },
    ),
  };

  let editorPanelConfigurations = [
    AnswersPanelConfiguration.create({ container }),
    StandardAudiencePanelConfiguration.create({
      ...audiencePanelConfiguration,
      title: intl.t('ai-agent.profiles.config-generator.audience-header'),
    }),
    BotModeContentPanelConfiguration.create({ container }),
    BotIntroPanelConfiguration.create({ container }),
    PreHandoverPanelConfiguration.create({ container }),
    GenericTriagePanelConfiguration.create({ container }),
    StandardSchedulePanelConfiguration.create({
      title: intl.t('ai-agent.profiles.config-generator.schedule'),
      container,
    }),
  ];

  function finOptInCheck(postAcceptCallback) {
    if (contentEditorService.activeObject?.useAiAnswers && !finOptInService.hasOptedInToFin) {
      finOptInService.postAcceptCallback = postAcceptCallback;
      finOptInService.openFinOptInModal();
    } else {
      postAcceptCallback.perform();
    }
  }

  let editModeConfig = EditModeConfig.create({
    headerComponentConfigs: [
      StandardEditModeHeaderConfiguration.create({
        container,
        preActivationCheck: finOptInCheck,
        actionButtons: {
          allowDeactivate: true,
          allowUnschedule: true,
          allowActivateWhenPaused: true,
        },
        moreDropdownConfig: MoreDropdownConfiguration.create({
          hideCsvExportOption: true,
          hideTagOption: true,
          container,
        }),
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/workflows/preview-button',
          analyticEvent: {
            place: 'resolution_bot_behavior',
          },
        }),
        bannerConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/fin/free-trial-banner',
          class: 'pb-4',
          usingAiAnswers: contentEditorService.activeObject?.useAiAnswers,
        }),
        ctaConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/fin/learn-about-fin-dropdown',
          analyticEvent: {
            place: 'resolution_bot_behavior',
          },
        }),
        titleInputConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/fin/title-input',
        }),
      }),
    ],
    editorPanelConfigs: editorPanelConfigurations,
    footerComponentConfigs: [],
  });

  return editModeConfig;
}
