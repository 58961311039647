/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type Admin from 'embercom/models/admin';
import { type AccessType } from 'embercom/services/report-access-service';

export default class ReportAccessControlList extends Model {
  @service appService: $TSFixMe;

  @attr('string') declare adminId?: string;
  @attr('string') declare reportId: string;
  @attr('string') declare accessType: AccessType;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  get app() {
    return this.appService.app;
  }

  get admin(): Admin | null {
    if (this.adminId) {
      return this.store.peekRecord('admin', this.adminId);
    }
    return null;
  }

  get isWorkspaceAccessControl() {
    return this.adminId === null;
  }
}
