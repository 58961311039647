/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import { type BlockList } from '@intercom/interblocks.ts';
import SendState, { type SendStateWireFormat } from 'embercom/objects/inbox/send-state';
import { tracked } from '@glimmer/tracking';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';
import CustomBotSummary, {
  type CustomBotSummaryWireFormat,
} from 'embercom/objects/inbox/custom-bot-summary';
import { Channel } from 'embercom/models/data/inbox/channels';
import {
  EmailMetadata,
  type EmailMetadataWireFormat,
} from 'embercom/objects/inbox/renderable/email-metadata';

export enum RatingType {
  Positive = 'positive',
  Negative = 'negative',
}

interface AnswerRatingWireFormat {
  answer_id: number;
  answer_title: string;
  rating?: RatingType;
}

export class AnswerRating {
  readonly answerID: number;
  readonly answerTitle: string;
  @tracked rating?: RatingType;

  constructor(answerID: number, answerTitle: string, rating?: RatingType) {
    this.answerID = answerID;
    this.answerTitle = answerTitle;
    this.rating = rating;
  }

  static deserialize(json: AnswerRatingWireFormat): AnswerRating {
    return new AnswerRating(json.answer_id, json.answer_title, json.rating);
  }
}

interface BotCommentWireFormat {
  renderable_type: RenderableType;
  blocks: BlockList;
  entity: AdminSummaryWireFormat;
  send_state?: SendStateWireFormat;
  answer_rating?: AnswerRatingWireFormat;
  custom_bot_summary?: CustomBotSummaryWireFormat;
  readonly channel?: Channel;
  email_metadata?: EmailMetadataWireFormat;
  related_part_id?: number;
}

export default class BotComment implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.BotComment;
  readonly blocks: BlockList;
  readonly entity: AdminSummary;
  readonly answerRating?: AnswerRating;
  readonly customBotSummary?: CustomBotSummary;
  readonly channel?: Channel;
  readonly relatedPartId?: number;

  @tracked sendState?: SendState;
  @tracked emailMetadata?: EmailMetadata;

  get creatingEntity() {
    return {
      type: EntityType.Bot,
      id: this.entity.id,
      object: this.entity,
    };
  }

  get styles() {
    if (this.hasError) {
      return {
        backgroundColor: 'bg-sky dark:bg-dm-blue-dark dark:bg-opacity-50',
        textColor: 'text-gray dark:text-dm-gray',
      };
    }
    if (this.channel === Channel.Email) {
      return {
        backgroundColor: 'bg-sky dark:bg-dm-blue-dark',
        textColor: 'text-blue-darkest dark:text-dm-white',
        backgroundColorHover: 'bg-sky-dark dark:bg-blue-darkest',
      };
    }
    return {
      backgroundColor: 'bg-sky dark:bg-dm-blue-dark',
      textColor: 'text-blue-darkest dark:text-dm-white',
      backgroundColorHover: 'bg-sky-dark dark:bg-blue-darkest',
    };
  }

  get ticketStyles() {
    return {
      backgroundColor: 'bg-white dark:bg-black',
      textColor: 'text-black dark:text-dm-white',
      backgroundColorHover: 'bg-gray-lightest dark:bg-dm-gray-darker',
    };
  }

  get hasError() {
    return this.sendState?.sendState === 0;
  }

  updateSendState(newSendState: SendState) {
    this.sendState = newSendState;
  }

  constructor(
    blocks: BlockList,
    entity: AdminSummary,
    sendState?: SendState,
    answerRating?: AnswerRating,
    customBotSummary?: CustomBotSummary,
    channel?: Channel,
    emailMetadata?: EmailMetadata,
    relatedPartId?: number,
  ) {
    this.blocks = blocks;
    this.entity = entity;
    this.sendState = sendState;
    this.answerRating = answerRating;
    this.customBotSummary = customBotSummary;
    this.channel = channel;
    this.emailMetadata = emailMetadata;
    this.relatedPartId = relatedPartId;
  }

  get isRatingRequest() {
    let [block] = this.blocks;
    return this.blocks.length === 1 && block.type === 'conversationRating';
  }

  static deserialize(json: BotCommentWireFormat): BotComment {
    let emailMetadata = json.email_metadata
      ? EmailMetadata.deserialize(json.email_metadata)
      : undefined;
    return new BotComment(
      json.blocks,
      AdminSummary.deserialize(json.entity),
      json.send_state && SendState.deserialize(json.send_state),
      json.answer_rating ? AnswerRating.deserialize(json.answer_rating) : undefined,
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
      json.channel,
      emailMetadata,
      json.related_part_id,
    );
  }
}
