/* RESPONSIBLE TEAM: team-workflows */

import { ParentAction, ChildAction } from './action';
import { Channel } from 'embercom/models/data/inbox/channels';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { type SearchMultipleTypesResponse } from 'embercom/services/quick-search';
import { NamedIcon } from 'embercom/objects/inbox/command-k/icons';
import type TriggerableCustomBot from 'embercom/objects/inbox/triggerable-custom-bot';

const INCOMPATIBLE_CHANNEL_NAME_TRANSLATION_KEYS: { [key in Channel]: string } = {
  [Channel.Unknown]: 'inbox.command-k.trigger-workflow.incompatible-channel.unknown',
  [Channel.Desktop]: 'inbox.command-k.trigger-workflow.incompatible-channel.desktop',
  [Channel.Android]: 'inbox.command-k.trigger-workflow.incompatible-channel.android',
  [Channel.IOS]: 'inbox.command-k.trigger-workflow.incompatible-channel.ios',
  [Channel.Email]: 'inbox.command-k.trigger-workflow.incompatible-channel.email',
  [Channel.Twitter]: 'inbox.command-k.trigger-workflow.incompatible-channel.twitter',
  [Channel.Facebook]: 'inbox.command-k.trigger-workflow.incompatible-channel.facebook',
  [Channel.FacebookMessenger]:
    'inbox.command-k.trigger-workflow.incompatible-channel.facebook-messenger',
  [Channel.Whatsapp]: 'inbox.command-k.trigger-workflow.incompatible-channel.whatsapp',
  [Channel.Instagram]: 'inbox.command-k.trigger-workflow.incompatible-channel.instagram',
  [Channel.PhoneSwitch]: 'inbox.command-k.trigger-workflow.incompatible-channel.phone-switch',
  [Channel.SMS]: 'inbox.command-k.trigger-workflow.incompatible-channel.sms',
  [Channel.PhoneCall]: 'inbox.command-k.trigger-workflow.incompatible-channel.phone-call',
  [Channel.ZendeskTicket]: 'inbox.command-k.trigger-workflow.incompatible-channel.zendesk-ticket',
};

export default class TriggerWorkflowAction extends ParentAction {
  id = 'trigger-workflow';
  icon = new NamedIcon('workflows', 'standard');
  searchableTypes = [SearchableType.TriggerableCustomBot];
  paneComponent = 'inbox2/command-k/trigger-workflow/pane';

  buildTopLevelItems(
    _query: string,
    quickSearchResults: SearchMultipleTypesResponse,
  ): Array<ChildAction> {
    return this.buildResults(quickSearchResults);
  }

  buildResults(quickSearchResults: SearchMultipleTypesResponse): Array<ChildAction> {
    let results = quickSearchResults[SearchableType.TriggerableCustomBot]?.results;
    if (!results) {
      return [];
    }

    let currentChannel: Channel = this.context.conversation.currentChannel;

    return results.map((result) => {
      let triggerableCustomBot = result.data as TriggerableCustomBot;
      let isUnselectable = !this.isCompatibleWithCurrentChannel(
        currentChannel,
        triggerableCustomBot.details.compatibleChannels,
      );
      return new ChildAction({
        parent: this,
        id: triggerableCustomBot.id.toString(),
        label: triggerableCustomBot.name,
        value: triggerableCustomBot.details,
        optionComponent: 'inbox2/command-k/trigger-workflow/option',
        isUnselectable,
        isUnselectableReason: isUnselectable
          ? this.intl.t(INCOMPATIBLE_CHANNEL_NAME_TRANSLATION_KEYS[currentChannel])
          : undefined,
      });
    });
  }

  isCompatibleWithCurrentChannel(currentChannel: Channel, channels: Channel[]): boolean {
    return currentChannel === Channel.Unknown || channels.includes(currentChannel);
  }
}
